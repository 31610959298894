body {
  background: #3e4041;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vertical-center {
  position: absolute;
  top: 40%;
  left:50%;
  transform: translate(-50%, -50%)
}