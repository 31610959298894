
.Horsie-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Horsie-logo {
    animation: Horsie-logo-spin-positive infinite 5s alternate linear;
  }
}

.Horsie-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Horsie-link {
  color: #61dafb;
}

@keyframes Horsie-logo-spin-positive {
  from {
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(10deg);
  }
}