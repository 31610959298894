

h2 {
    color: #00e1ff;
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 8px;
}
h1 {
    font-size: 22px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.6);
    margin: 0 0 16px;
}
p {
    line-height: 140%;
    margin: 16px 0 24px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
}

#message { 
    text-align: center;
    background: rgb(43, 43, 43);
    max-width: 360px;
    /*margin: 16px auto 16px;*/
    padding: 32px 24px;
    border-radius: 3px;
}
